import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';

import freeBg from '@/assets/images/img-pd-free.png';
import payPng from '@/assets/images/pay-title.png';
import payMethodAsYouGoSvg from '@/assets/svg/pay-method-asyougo.svg';
import payMethodSubSvg from '@/assets/svg/pay-method-sub.svg';
import type { Product,ProductCommon } from '@/type/config';

import styles from './Price.less';
import ProductTable from './ProductTable';

interface PricingProps{
  isPay: boolean;
  slideinClass: string;
  type: Product['type'];
  billingSubscription: Product['billingSubscription'];
  billingPayAsYouGo: Product['billingSubscription'];
  region: Product['region'];
  choosePay:ProductCommon['choosePay']
}

const tableTabAnimate = (dom: HTMLElement, oldDom: HTMLElement, direction: 'left' | 'right') => {
  dom?.animate(
    [
      {
        transform: `translate(${direction === 'left' ? '-100%' : '100%'})`,
        opacity: 0,
      },
      {
        transform: 'initial',
        opacity: 1,
      },
    ],
    {
      duration: 300,
      iterations: 1,
    }
  );
  const newDomP = dom.parentElement;
  const oldDomP = oldDom.parentElement;
  if (!newDomP || !oldDomP) return;

  const oldHeight = oldDomP.getBoundingClientRect().height;
  const newHeight = newDomP.getBoundingClientRect().height;
  if (oldHeight === 0 || newHeight === 0) return;
  newDomP.animate([
    {
      height: `${oldHeight}px`,
    },
    {
      height: `${newHeight}px`,
    },
  ], 300);
};
const handleChange = (oldKey: string, newKey: string, items: { key: string }[]) => {
  const newDom = document.getElementById(newKey);
  const oldDom = document.getElementById(oldKey);

  if (!newDom || !oldDom) return;
  if (oldKey === newKey) return;

  let direction: 'left' | 'right' = 'left';
  const oldIndex = items.findIndex(item => item.key === oldKey);
  const newIndex = items.findIndex(item => item.key === newKey);
  if (newIndex > oldIndex) { direction = 'right'; }
  tableTabAnimate(newDom, oldDom, direction);
};

const Pricing = (props: PricingProps) => {
  const { isPay, region, slideinClass, billingPayAsYouGo, billingSubscription, type,choosePay } = props;
  const oldRegion = useRef(region?.[0]?.region || '');
  const oldTabs = useRef(region.map(item => { return { region: item.region, tab: `${item.region}-${item.tabs[0].tabName}` }; }));
  // 切换回产品页重置oldRegion与oldTab防止切换高度问题
  useEffect(() => {
    oldRegion.current = region?.[0]?.region || '';
    oldTabs.current = region.map(item => { return { region: item.region, tab: `${item.region}-${item.tabs[0].tabName}` }; });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  const items: TabsProps['items'] = region.map(item => {
    const secondItems = item.tabs.map(secItem => {
      const key = `${item.region}-${secItem.tabName}`;
      return {
        key,
        label: secItem.tabName,
        forceRender: true,
        children: (
          <div key={key} className={styles['table-content']}>
            <ProductTable {...secItem} id={key} />
          </div>
        ),
      };
    });
    const onSecondChange = (key: string) => {
      const oldTab = oldTabs.current?.find(oldTabItem => oldTabItem.region === oldRegion.current);
      if (!oldTab) return;
      handleChange(oldTab?.tab, key, secondItems);
      oldTab.tab = key;
    };
    return {
      key: item.region,
      forceRender: true,
      label: item.region,
      children: <Tabs onChange={onSecondChange} id={item.region} key={item.region} tabBarGutter={12} className={styles['type-tab']} type="card" defaultActiveKey={secondItems[0].key} items={secondItems} />,
    };
  });
  const onChange = (key: string) => {
    handleChange(oldRegion.current, key, items);

    oldRegion.current = key;
  };
  // 强制让未渲染的tab渲染，便于动画获取高度
  useEffect(() => {
    const tabpanes = document.querySelectorAll('.ant-tabs-tabpane');
    tabpanes.forEach(item => {
      if (item.classList.contains('ant-tabs-tabpane') && !item.classList.contains('ant-tabs-tabpane-active')) {
        item.classList.add('ant-tabs-tabpane-hidden');
      }
    });
  }, [type]);
  return isPay ? (
    <div id="product-pay" className={styles['slidein-contain']}>
      <div className={classnames(styles.pay, slideinClass)}>
        <div className={styles['pay-content']}>
          <div className={styles['pay-content-title']}>
            <img src={payPng} alt="" />
            <span>{choosePay?.title}</span>
            <img src={payPng} alt="" />
          </div>
          <div className={styles['pay-content-description']}>
            {choosePay?.description}
          </div>
          <div className={styles['pay-content-method']}>
            {
              billingSubscription && (
                <div className={styles['pay-content-method-item']}>
                  <img src={choosePay?.subscriptionUrl??payMethodSubSvg} alt="" />
                  <div>
                    <span>{choosePay?.subscriptionTitle}</span>
                    <div>{choosePay?.subscriptionDes}</div>
                  </div>
                </div>
              )
            }
            {
              billingPayAsYouGo && (
                <div className={styles['pay-content-method-item']}>
                  <img src={choosePay?.payAsYouGoUrl??payMethodAsYouGoSvg} alt="" />
                  <div>
                    <span>{choosePay?.payAsYouGoTitle}</span>
                    <div>{choosePay?.payAsYouGoDes}</div>
                  </div>
                </div>
              )
            }

          </div>
        </div>
      </div>

      <div className={classnames(styles.table, slideinClass)}>
        <Tabs className={classnames(styles['region-tab'],'contain')} centered items={items} onChange={onChange} />
      </div>
    </div>
  ) : (
    <div id="product-pay" className={styles['slidein-contain']}>
      <div className={classnames(styles.free, slideinClass)}>
        <div className={styles['free-content']}>
          <div className={styles['free-content-title']}>ABSOLUTELY FREE!</div>
          <div className={styles['free-content-description']}>Our free product is designed to meet your needs without putting a dent in your budget.</div>
          <img src={freeBg} alt="" />
          <div className={styles['free-content-benefit']}>
            Start your journey
          </div>
          <div className={styles['free-content-benefit']}>
            Explore its capabilities
          </div>
          <div className={styles['free-content-benefit']}>
            Enjoy the features
          </div>
        </div>
      </div>
    </div>
   
  );
};

export default Pricing;
