import React, { ReactNode } from 'react';

import styles from './index.less';

interface BannerProps {
  bannerIsVideo: boolean;
  bannerUrl: string;
  bannerBackgroundColor?: string;
  children?: ReactNode;
  height?: number;
}
const Banner = (props: BannerProps) => {
  const { bannerIsVideo, bannerUrl, bannerBackgroundColor, children, height } = props;
  return (
    <div style={{ backgroundColor: bannerBackgroundColor, height }} className={styles.banner}>
      {
        bannerIsVideo ? (
          <div className={styles['banner-video']}>
            <video src={bannerUrl} autoPlay muted loop disablePictureInPicture className={styles.banner_video}>
              <source src={bannerUrl} type="video/mp4" />
              <track kind="captions" />
            </video>
            <div
              className={styles['banner-video-left-mask']}
              style={{
                backgroundImage: `linear-gradient(-90deg, ${bannerBackgroundColor}00 0%, ${bannerBackgroundColor}FF 100%)`,
              }}
            />
            <div
              className={styles['banner-video-right-mask']}
              style={{
                backgroundImage: `linear-gradient(90deg, ${bannerBackgroundColor}00 0%, ${bannerBackgroundColor}FF 100%)`,
              }}
            />
          </div>

        )
          :
          <img src={bannerUrl} alt="" />
      }

      <div className={styles['banner-content']}>
        {children}
      </div>

    </div>
  );
};

export default Banner;
