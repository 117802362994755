import classNames from 'classnames';
import React, { useState } from 'react';

import type { FeatureOrBenefit } from '@/type/config';

import styles from './Benefits.less';

interface BenefitsProps {
  title: string;
  desc: string;
  benefits: FeatureOrBenefit[];
  style?: React.CSSProperties;
}
const BenefitsItem = (props: { item: FeatureOrBenefit }) => {
  const { item } = props;
  const [isHover, setIsHover] = useState(false);
  return (
    <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <div>
        <img src={item[isHover ? 'iconUrlHover' : 'iconUrl']} alt="" />
        <div>
          <span>{item.title}</span>
          <div>{item.description}</div>
        </div>
      </div>
    </div>
  );
};

const Benefits = (props: BenefitsProps) => {
  const { title, desc, benefits, style } = props;

  return (
    <div style={style} className={classNames(styles['benefits-content'], 'contain')}>
      <span className={styles['benefits-content-title']}>{title}</span>
      <span className={styles['benefits-content-description']}>{desc}</span>
      <div className={styles['benefits-content-item']}>
        {
        benefits.map(item => {
          return (
            <BenefitsItem key={item.title} item={item} />
          );
        })
      }
      </div>

    </div>
  );
};

export default Benefits;
