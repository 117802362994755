import type { BaseConfig } from '@/type/config'
import { useNavigate } from 'react-router';
export interface ToConsoleProps {
  isReplace?: boolean;
  isBlank?: boolean;
  targetRoute?: string;
  isToConsole?:boolean;
}

const useToConsole = () => {
  const BASECONFIG = (window as any).BASECONFIG as BaseConfig;
  const baseUrl = BASECONFIG?.baseUrl
  const navigate = useNavigate();
  const toConsole = (props?: ToConsoleProps) => {
    const isLogin=localStorage.getItem('checkIsLogin')==='true'
    const { isToConsole = true, isReplace = false, isBlank = false, targetRoute =isLogin?'/work_bench/dashboard': '/login' } = props || {}

    let consoleUrl = targetRoute
    
    const isLink = targetRoute.startsWith('http')
     // 直接跳转login，如果有对应登录态会自动进入dashboard
    if (!isLink&&isToConsole) {
      consoleUrl = baseUrl + targetRoute
    }
    
    if (baseUrl) {
      if (isBlank) {
        window.open(consoleUrl)
      }
      else if (consoleUrl.startsWith('http')) {
        isReplace ?
          window.location.replace(consoleUrl)
          :
          window.location.href = consoleUrl
      }
      else {
        navigate(consoleUrl, { replace: isReplace })
      }
    }
  }
  return { toConsole }
}


export default useToConsole