// 跳转控制台使用的枚举

export enum UrlTypeEnum {
  Internal = 'LAN',
  External = 'WAN',
  ExternalMenu = 'EWM',
}

export enum TimestampEnum {
  timestamp = 'cre_timestamp',
}

export enum ConsoleOfferCode {
  EIP = 'd050f7c1-0830-4b25-b35e-6ab1cb53cbff', // Elastic IP Addresses
  OSS = '8e365595-e637-407f-8f95-027f074931ed', // Object Storage Service
  NAS = 'eb6a95a6-dbf9-11ec-a31f-660b91367a8c', // NAS File System
  NAS_STORAGE = '668e04fe-dc16-11ec-a06c-660b91367a8c',
  NAT = 'ac8b3fa2-5356-4adb-b4f6-8f3c8d29df3c', // NAT Gateways
  VPN = '74b65290-05b1-4092-b1cc-4dfaac0a3115', // VPN Gateways
  OSS_STORAGE = 'OSS_STORAGE',
  OSS_TRAFFIC = 'OSS_TRAFFIC',
  OSS_OUTBOUND_TRAFFIC_PLAN = '5cea62a7-dc0c-11ec-a561-660b91367a8c',
  OSS_STANDARD_STORAGE_PLAN = '51be2783-dc10-11ec-b851-660b91367a8c',
  OSS_DISASTER_STORAGE_PLAN = 'de30f342-7544-11ed-8a93-ee6277dc7a45',
  SLB = '6ab57bb8-6684-4aa3-b854-6e7c55739317', // Server Load Balancer
  WAF = 'ca7b27c9-eded-11ec-a097-bef61551533d', // Web Application Firewall
  WAF_RESOURCES = '987bb5d8-f076-11ec-a097-bef61551533d',
  DDOS = 'c1b062ce-eded-11ec-a097-bef61551533d', // Anti-DDoS
  DDOS_RESOURCES = 'a0e01f07-f076-11ec-a097-bef61551533d',
  DISK = '9bfe7549-c8b6-4bfc-91e4-9b93295298d0',
  DISK_NEW = '7c1a4ae4-cdac-46d6-aa6f-2b3abf48759c',
  ECS = 'ff7d8ca3-5f2b-4363-900d-6cdab9be30ef', // Elastic Compute Service
  ESS = 'dc8666c9-edec-11ec-a097-bef61551533d', // Auto Scaling
  ACK = 'e814a2e1-edec-11ec-a097-bef61551533d', // Container Service - Kubernetes
  TSM = '8399b3e1-567f-470b-8ae0-2093ea1a60dd', // Traffic Security Monitoring
  VPC = 'c96e0b17-ae9c-4b28-9f23-01ae5b76fa42', // Virtual Private Clouds
  // BS = '9bfe7549-c8b6-4bfc-91e4-9b93295298d0', // Block Storage
  SG = '8b3533d7-9569-4a61-961e-62c0600f308d', // Server Guard
  ACR = 'a580ea81-5728-4f81-bec8-6a01c4b881f4', // Container Registry
  HAVIP = '953d2c39-ce7e-434b-a038-172ae9571a0f', // High-availability Virtual IP Address
  EXP = '9d60fd24-f1e0-4e4b-9d4d-33e770af3794', // Express Connect
  RDS = '974513cb-c764-4b64-b6aa-5820783b0c1d', // ApsaraDB RDS
  MDB = '72dfff8d-5804-49c3-a47c-58f6d9191d83', // ApsaraDB for MongoDB
  DBS = 'c9fa911d-82fb-4227-8246-b264ba265c68', // Database Backup
  DTS = '9c8a69b1-056e-48fe-aa22-226c944eedb0', // Data Transmission Service
  ROS = 'cd8dced6-9756-4790-bd04-9fd89abb3426', // Resource Orchestration Service
  CM = 'b1cc8968-0c11-43cf-b81b-cf720ece8de1', // CloudMonitor
  API = 'bb889034-ecc1-40a3-b1ed-a7930e529ed8', // ApiGateway
  HBR = 'fd1c7b08-e49f-4ba2-8f84-c9706d33180c', // Hybrid Backup Recovery
  SA = '52c3ea2f-9618-41de-9388-b9df9f3454f8', // Situation Awareness
  MSC = '588278b0-63bf-8dfa-e9db-07600712beef', // MongoDB Sharded Cluster
  MRS = '0daac28a-bc0e-8f9e-52d8-0cad9590b4d0', // MongoDB Replica Set
  DBAAS = '7b6fb6d4-2f55-4729-a2e9-b7f11abc702e',
  ROUTE_TABLE = 'e93cc77e-113f-467e-b077-378bfbad05d8',
  VSWITCH = '33948b4e-d4dc-4aed-b725-5f0722213527',
  RDS_MYSQL='f91b726a-3d0f-42a9-b62d-b4e1bc91ecf8',
  RDS_SQLSERVER ='343eea79-cb46-43bc-a611-004f93052162',
  RDS_MARIADB='f4a613f6-7159-11ed-8a93-ee6277dc7a45',
  RDS_PSQL='d9f46a52-223e-1c86-4f9b-a43e50d6768d',
  REDIS='acf0cc2e-d388-453a-b7af-81c2951c9a42',
  SLS='cbbc07a7-21a6-40b8-8bb9-4594197c9aa4',
  mongodb='588278b0-63bf-8dfa-e9db-07600712beef',
  TDS='52c3ea2f-9618-41de-9388-b9df9f3454f8',
  SMC='ea17545593034d2bae911be489a1bd82'
}

export const ConsoleOfferCodeReverse = {} as any;
Object.keys(ConsoleOfferCode).forEach(keyName => {
  const propName = ConsoleOfferCode[keyName as keyof typeof ConsoleOfferCode];
  ConsoleOfferCodeReverse[propName] = keyName;
});
