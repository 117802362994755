import { Avatar, Divider, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';

import copySvg from '@/assets/svg/copy.svg';
// import avatarLogo from '@/assets/images/avatar.png'
import { sendLogout } from '@/services';

import style from './AvatarDropdown.less';
import { BaseConfig } from '@/type/config';

const { Paragraph } = Typography;
const AvatarDropdown = ({ userInfo = {}, loadLogIn, avatar }: any) => {
  const BASECONFIG=(window as any)?.BASECONFIG as BaseConfig
  const {userDropDown}=BASECONFIG
  // const [avatar, setAvatar] = useState<string>('')
  const copyFun = async () => {
    navigator.clipboard.writeText(userInfo?.openId);
  };


  const logout = async () => {
    await sendLogout();
    localStorage.removeItem('X-CSRF-TOKEN');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentMenu');
    window.localStorage.removeItem('subscriptionCode');
    window.sessionStorage.removeItem('subscriptionCode');
    window.localStorage.removeItem('subscriptions');
    window.localStorage.removeItem('subFirstEnter');
    window.localStorage.removeItem('needControl');
    window.localStorage.removeItem('localPageMatch');
    window.sessionStorage.removeItem('clearACKCookie');
    loadLogIn();
    window.location.reload();
  };

  const getEmail = (mail = '') => {
    const arr = mail.split('@');
    if (arr.length === 2) {
      return (
        <Tooltip
          title={mail}
          overlayClassName="avatarDropdown-mail-tooltip"
        >
          <div className="mail">
            <div>@{arr[1]}</div>
            <div><span>{arr?.[0]}</span></div>
          </div>
        </Tooltip>
      );
    }
    return mail;
  };
  return (
    <div className={classNames(style.avatarDropdown, 'cursor-pointer', userInfo?.username ? style.hasAvatarDropdown : '')}>
      {avatar && (
      <Avatar
        size={32}
        src={avatar}
        alt="avatar"
      />
      )}
      <div className="avatarDropdown-userInfo">
        <div>
          <div className={classNames('avatarDropdown-userInfo-card', 'avatarDropdown-userInfo-card1')}>
            <div className="avatarDropdown-userInfo-card1-text">
              <div className="avatarDropdown-userInfo-card1-text1">{getEmail(userInfo?.username)}</div>
              <div className="avatarDropdown-userInfo-card1-text2"><span>{userInfo?.dunningState}</span></div>
            </div>
          </div>

          <Divider />
          <div className={classNames('avatarDropdown-userInfo-card', 'avatarDropdown-userInfo-card2')}>
            <div className="accountId">
              <div>{userDropDown?.accountId}</div>
              <Paragraph
                copyable={{
                  text: userInfo?.openId,
                  icon: [<img src={copySvg} onClick={copyFun} className="cursor-pointer" alt="" />],
                }}
              ><span>{userInfo?.openId}</span>
              </Paragraph>
            </div>
            <div>
              <div>{userDropDown?.userRole}</div>
              <span>{userInfo?.roles?.[0].roleName}</span>
            </div>
            {!['P', 'A'].includes(userInfo?.customerType || '') && (
            <div> <div>Enterprise</div><span>{userInfo?.userStatusRspVoList?.[0].orgName}</span>
            </div>
            )}
          </div>
          <Divider />
          <div className={classNames(
            'avatarDropdown-userInfo-card',
            'avatarDropdown-userInfo-card3',
            'cursor-pointer'
          )}
          >
            <div
              onClick={() => { logout(); }}
            >{userDropDown?.logOut}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AvatarDropdown;
