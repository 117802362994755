import { useRequest } from 'ahooks';
import { ConfigProvider, Layout, Skeleton } from 'antd';
import React, { lazy, ReactNode, Suspense, useLayoutEffect } from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import defaultBrowserLogo from '@/assets/images/bcx_browser.ico';
import defaultLogo from '@/assets/images/bcx-logo-black.png';
import defaultAvatar from '@/assets/images/img-nav-user.png';
import LayoutFooter from '@/components/LayoutFooter';
import LayoutHeader from '@/components/LayoutHeader';
import Exception404 from '@/page/404';
import * as services from '@/services';

// 产品页不懒加载，否则加载中header消失下拉框仍在
import Products from './page/products';
import style from './router.less';
import type { BaseConfig } from './type/config';

const { Header } = Layout;

const Home = lazy(() => import('./page/home'));
const DocumentCenter = lazy(() => import('./page/documentCenter'));
const Contact = lazy(() => import('./page/contact'));
const ContactNow = lazy(() => import('./page/consultNow'));
const Pricing = lazy(() => import('./page/pricing'));
const FreeTrial = lazy(() => import('./page/freeTrial'));
const Marketplace = lazy(() => import('./page/marketplace'));
const SolutionPage = lazy(() => import('./page/solution'));

interface Routers {
  element: React.ReactNode;
  title?: string;
  index?: boolean;
  path?: string;
}

const routers: Routers[] = [
  {
    element: <Home />,
    index: true,
  },
  {
    element: <Products />,
    path: 'products',
  },
  {
    element: <Pricing />,
    path: 'pricing',
  },
  {
    element: <DocumentCenter />,
    path: 'documentCenter',
  },
  {
    element: <Exception404 />,
    path: '*',
  },
  {
    element: <Contact />,
    path: 'support',
  },
  {
    element: <ContactNow />,
    path: 'contactNow',
  },
  {
    element: <FreeTrial />,
    path: 'freeTrial',
  },
  {
    element: <Marketplace />,
    path: 'marketplace',
  },
  {
    element: <SolutionPage />,
    path: 'solution',
  },
];

interface RouterGuardProps {
  children: ReactNode;
}
const RouterGuard = (props: RouterGuardProps) => {
  const location = useLocation();
  const path = location.pathname + location.search;
  // 页面切换回到顶部
  useLayoutEffect(() => {
    const dom = document.getElementById('scroll-container-id');
    if (dom) {
      dom.scrollTop = 0;
    }
  }, [path]);
  return <React.Fragment>{props.children}</React.Fragment>;
};

const Router = () => {
  const { data: userData } = useRequest(async () => {
    const { result = [] } = await services.queryTheme();
    const handleResult: any = {};
    result.forEach((element: any) => {
      const JsonParseValue = JSON.parse(element.configValue);
      handleResult[element.configCode] = JsonParseValue;
    });
    return {
      systemInfo: handleResult?.systemInfo,
      // browser: handleResult?.browser,
      // platformInterface: handleResult?.platformInterface,
    };
  });
  const { BASECONFIG } = window as any;
  const data = BASECONFIG as BaseConfig;

  const themeColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
  return (
    <Suspense fallback={<Skeleton active />}>
      <BrowserRouter basename="/homepage">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: themeColor,
            },
          }}
        >
          <Layout className={style.layou} id="scroll-container-id">
            <div className="layou-content">
              <Header className={style.header}>
                <LayoutHeader logo={data?.headerLogo || defaultLogo} avatar={data?.profilePicture || userData?.systemInfo?.profilePicture || defaultAvatar} />
              </Header>
              <RouterGuard>
                <Routes>

                  {routers.map(item => (
                    <Route
                      element={(
                        <>
                          <Helmet>
                            <title>{data?.title || ''}</title>
                            <link rel="icon" type="image/png" href={data?.hrefLogo || defaultBrowserLogo} />
                          </Helmet>
                          {item.element}
                        </>
                      )}
                      index={item.index}
                      path={item.path}
                      key={`${item.index} ${item.path}`}
                    />
                  ))}

                </Routes>
              </RouterGuard>
              <LayoutFooter logo={data?.footerLogo || defaultLogo} />
            </div>
          </Layout>
        </ConfigProvider>

      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
