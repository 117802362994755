import { useEffect } from 'react';

import styles from './index.less';

/*
注意！！！！！！！！！！
使用useSlideIn的元素如果同时使用了antd Anchor
请在元素外层套一个div将anchor定位的id设在外层
否则会出现anchor定位错误的问题
*/
const useSlideIn = (itemClass: string) => {
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach((ele: IntersectionObserverEntry) => {
        const { boundingClientRect, intersectionRect, target, isIntersecting } = ele;

        // const hasSlideBottom=ele.target.classList.contains(styles['slidein-from-bottom']);
        // const hasSlideTop=ele.target.classList.contains(styles['slidein-from-top']);
        const hasSlide = target.classList.contains(styles['slidein-end']);
        
        if (boundingClientRect.top === intersectionRect.top && isIntersecting && !hasSlide) {
          // 从下方进入
          // console.log('从下方进入', boundingClientRect.top, intersectionRect.top, ele.target)
          target.classList.add(styles['slidein-from-bottom']);
          target.classList.add(styles['slidein-end']);
        } else if (boundingClientRect.top < 0 && ele.isIntersecting && !hasSlide) {
          // 从上方进入
          // console.log('从上方进入', boundingClientRect.top, intersectionRect.top, ele.target)
          target.classList.add(styles['slidein-from-top']);
          target.classList.add(styles['slidein-end']);
        } else if (intersectionRect.top === 0 && boundingClientRect.top > 0 && !isIntersecting && hasSlide) {
          // 从下方离开
          // console.log('从下方离开', boundingClientRect.top, intersectionRect.top, ele.target)
          target.classList.remove(styles['slidein-end']);
          ele.target.classList.remove(styles['slidein-from-bottom']);
          ele.target.classList.remove(styles['slidein-from-top']);
        } else if (intersectionRect.top === 0 && boundingClientRect.top < 0 && !isIntersecting && hasSlide) {
          // 从上方离开
          // target.classList.remove(styles['slidein-from-top'])
          // target.classList.remove(styles['slidein-from-bottom'])
        }
      });
    });
    const slideinItem = document.querySelectorAll(`.${itemClass}`) || [];
    // console.log(slideinItem)

    slideinItem.forEach((item: Element) => {
      intersectionObserver.observe(item);
    });
    return () => {
      slideinItem.forEach(() => {
        intersectionObserver.disconnect();
      });
    };
  });
};
export default useSlideIn;
