import { useDocumentVisibility, useRequest } from 'ahooks';
import { useEffect } from 'react';

import { ConsoleOfferCode } from '@/enum/toConsole';
import * as services from '@/services';

type PurchaseProps={
  type: string; offerCode?: string;
}

const useToPurchase = ({ type, offerCode }: PurchaseProps) => {
  // 查询regionList
  const { data: regionList, loading: regionListLoading, run: getRegion, cancel: cancelRegion } = useRequest(
    async () => {
      const res = await services.queryRegion();
      if (!res) return [];
      const { result = [] } = res;
      const map = new Map();
      const labelMap = new Map();
      const resp = result?.map((item: any) => {
        map.set(item?.idcRegionCode, item?.cloudPlatform);
        labelMap.set(item?.idcRegionCode, item?.idcRegionName);
        return {
          text: item?.idcRegionName,
          label: item?.idcRegionName,
          value: item?.idcRegionCode,
          id: item?.idcRegionId,
          endpointRegionCode: item?.endpointRegionCode,
          cloudPlatform: item?.cloudPlatform,
        };
      });
      return resp;
    },
    {
      pollingInterval: 3000,
      onSuccess: res => {
        const isRegionUseful = res && res.length > 0;
        if (isRegionUseful) cancelRegion();
      },
    }
  );
  // 查询产品
  const { data: allProduct, loading: productDataLoading, run: getAllProduct, cancel: cancelAllProduct } = useRequest(async () => {
    const res = await services.getAllProduct();
    const { result, resultCode } = res;
    if (resultCode === '0') {
      const { allProduct } = result;
      return allProduct;
    }
    return undefined;
  },
  {
    pollingInterval: 3000,
    onSuccess: () => {
      const isProductUseful = !!allProduct;
      if (isProductUseful) cancelAllProduct();
    },
  }
  );

  const documentVisibility = useDocumentVisibility();

  const productData = allProduct?.subCatalogs.find(subCatalog =>
    subCatalog.productList.find(productItem =>
      productItem.offerCode === ConsoleOfferCode[type as keyof typeof ConsoleOfferCode] || productItem.offerCode === offerCode
    )
  )?.productList.find(productItem =>
    productItem.offerCode === ConsoleOfferCode[type as keyof typeof ConsoleOfferCode] || productItem.offerCode === offerCode
  );


  // 页面可见后重新获取一遍region和product
  useEffect(() => {
    if (documentVisibility) {
      getRegion();
      getAllProduct();
    }
  }, [documentVisibility, getRegion, getAllProduct]);

  // 查询roleMap
  const { data: role, loading: roleMapLoading, run: runRole } = useRequest(async (regionId: string) => {
    const res = await services.queryConsoleRole({ regionCode: regionId });
    if (res?.resultCode === '0') {
      sessionStorage.setItem('console_role', JSON.stringify({
        org_role: res.result.roleAr,
      }));
      return {
        org_role: res.result.roleAr,
      };
    }
    return null;
  }, { manual: true });

  let roleMap = role;
  if (!roleMap) {
    const roles = sessionStorage.getItem('console_role');
    try {
      if (roles) roleMap = JSON.parse(roles);
    } catch (err) {
      console.error(err);
    }
  }

  const regionId = productData?.productDetailInfo?.[0]?.regionCode;

  useEffect(() => {
    if (regionId) {
      runRole(regionId);
    }
  }, [type, regionId, runRole]);

  return {
    productData,
    productDataLoading,
    regionListLoading,
    regionList,
    roleMap,
    runRole,
    roleMapLoading,
  };
};

export default useToPurchase;
