import type { ButtonProps } from 'antd';
import { Button } from 'antd';
import classNames from 'classnames';
import omit from 'lodash/omit';
import React from 'react';

import styles from './index.less';

const SecondBtn = (props: ButtonProps) => {
  const { className, style } = props;
  const omitProps = omit(props, ['className']);
  
  return <Button type="primary" className={classNames(styles['second-btn'], !style?.backgroundColor && styles['second-btn-hover-bg'], className)} {...omitProps} />;
};

export default SecondBtn;
