import { useRequest } from 'ahooks';
import { Dropdown, Layout, Menu, Space } from 'antd';
import classNames from 'classnames';
import React, { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// import productCategory from '../../../public/config/productCategory.js';
// import icDropProduct from '@/assets/images/ic-drop-product.png';
import imgBgDropdown from '@/assets/images/img-bg-dropdown.png';
import imgDropdownShape from '@/assets/images/img-dropdown-shape.png';
import icPulldown from '@/assets/svg/ic-pulldown.svg';
import type { Solution as SolutionType, SolutionItem } from '@/type/config';

import style from './Products.less';

const { Sider, Content } = Layout;

const flagMap = {
  hot: 'Hot',
  new: 'New',
  coming: 'Coming soon',
};

const fetchImage = (url: string) => {
  return new Promise<string>(async res => {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        res(reader.result);
      } else {
        res(url);
      }
    };
    reader.readAsDataURL(blob);
  });
};

const ProductItem = (props: { data: SolutionItem; setVisible: (visible: boolean) => void }) => {
  const navigate = useNavigate();
  const { data, setVisible } = props;
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className={classNames('downDrawer-content-item', 'cursor-pointer')}
      key={data.tr_title}
      onClick={() => {
        setVisible(false);
        navigate(`/solution?type=${data.type}`);
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {data.iconUrl && <img src={data[isHover ? 'iconUrlHover' : 'iconUrl']} alt="" width="24" height="24" />}
      <div className="downDrawer-content-item-list">
        <div className="downDrawer-content-item-title">
          {data.tr_title}
          {data.flag && (
          <span className={`downDrawer-content-item-${data.flag}`}>
            {flagMap[data.flag as keyof typeof flagMap]}
          </span>
          )}
        </div>
        <div className="downDrawer-content-item-content">{data.tr_description}</div>
      </div>
    </div>
  );
};

const DownDrawer = forwardRef((props: { setVisible: (visible: boolean) => void }) => {
  const { setVisible } = props;

  const { category = [] }: SolutionType = (window as any)?.SOLUTION as SolutionType;

  const [selectMenuKey, setSelectMenuKey] = useState(category[0]?.tr_label);
  useEffect(() => {
    category.forEach(item => {
      const element = document?.getElementById(item.tr_label) || { onmouseenter: '' };
      element.onmouseenter = () => {
        setSelectMenuKey(item.tr_label);
      };
    });
  }, [category]);
  const { data: dropdownBg } = useRequest(async () => {
    const base64 = await fetchImage(imgBgDropdown);
    return base64;
  });

  return (
    <div className={style.downDrawer}>
      <Layout>
        <Sider style={{ background: `url('${dropdownBg}') 0%0%/cover` }}>
          <Menu
            selectedKeys={[selectMenuKey]}
            onClick={({ key }) => {
              setSelectMenuKey(key);
            }}
            items={category.map(item => ({
              key: item.tr_label,
              label: <span className="nav-text" id={item.tr_label}><span>{item.tr_label}</span></span>,
            }))}
          />
        </Sider>

        <Content className={classNames('downDrawer-content-bg', 'position-relative')}>
          {category.map(item => (
            <div
              className={classNames(
                'downDrawer-content',
                selectMenuKey === item.tr_label ? 'downDrawer-content-selectMenu' : '')}
              key={item.tr_label}
            >
              <div className="downDrawer-content-right-title">
                <img src={imgDropdownShape} alt="" width="13" />{item.tr_label }
                <img src={imgDropdownShape} alt="" width="13" />
                <hr />
              </div>
              {item?.list?.map(itemChild => (
                <ProductItem data={itemChild} setVisible={setVisible} key={itemChild.tr_title} />
              ))}
            </div>
          ))}

        </Content>

      </Layout>

    </div>
  );
});


const Solution = ({ bannerText }: { bannerText: string }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Dropdown
      dropdownRender={() => <DownDrawer setVisible={setVisible} />}
      open={visible}
      onOpenChange={flag => {
        setVisible(flag);
      }}
      overlayStyle={{ left: 0 }}
      forceRender
    >
      <div onClick={e => e.preventDefault()} className={style.downSvg}>
        <div className="downTitle">
          <Space size={2} className={visible ? 'space' : ''}>
            {bannerText}
            <img
              src={icPulldown}
              alt=""
              width="20"
              className={visible ? 'img-filter' : ''}
            />
          </Space>
        </div>
      </div>
    </Dropdown>
  );
};

export default Solution;
