import axios, { AxiosRequestConfig } from 'axios';
import { JSEncrypt } from 'jsencrypt';

enum GLOBAL_CONST {
  BASE_URL = 'cregw',
  APP_CODE = 'CRE',
  PUBLIC_KEY = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAs0asqvmg+2OTAh3tL725\n' +
    '1OctIn/LXSsPQNrN08N2eJXts5HtHWP0jZBNH4V81B46Q59HU33M0VafxKtfRWri\n' +
    '9ET4UjrX5FCxwHOL00VPsMwR+AbwWjbNMlov+ZQFto/QO3VkRNJHTenCedtLqJFL\n' +
    'AEBeOHZyYDEoh2oRMrPFcHehLS0B0qTE/NLZsPaeNbVSip/WzH2LZWqgwGoaQBeB\n' +
    '1K3Sip024WvebY15qra7vJQ5Wcofs6EBLjDH4tl6wTOX4oCzw0fYv66lLjsXoa/h\n' +
    'jQ+5gniqd1wk6XHYXxj2f46tCzGNLZCrVnavZUPnDIsXjNou/c7Fd/B/p3hI7dCq\n' +
    'OQIDAQAB',
  SALT = '0102030809060708',
}

export interface IOption extends AxiosRequestConfig {
  body?: { [propName: string]: any } | string;
  hasError?: boolean;
}

const encryptor = new JSEncrypt();
encryptor.setPublicKey(GLOBAL_CONST.PUBLIC_KEY);

const axiosConfig = {
  AT: '',
  RT: '',
  refreshTokenLoading: false,
  refreshTokenPromise: null,
  appCode: GLOBAL_CONST.APP_CODE,
};

const instance = axios.create({
  timeout: 50000,
  params: {
    _: Math.random(),
  },
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

instance.interceptors.request.use((config): any => {
  const ret = config;

  // 加入验签环节
  const timestamp = new Date().getTime();
  const securityKey = `${timestamp}${GLOBAL_CONST.SALT}`;
  const rsaPassWord = encryptor.encrypt(securityKey);
  if (!ret.headers) return ret;
  ret.headers['x-ts'] = timestamp;
  ret.headers['x-sign'] = rsaPassWord;

  // 存入appCode
  if (axiosConfig.appCode) {
    ret.headers['App-Code'] = axiosConfig.appCode;
  }

  return ret;
});

instance.interceptors.response.use(
  (response: any) => {
    const { status } = response;
    return new Promise((resolve, reject) => {
      if (status !== 200) reject(response);
      resolve(response);
    });
  },
  error => {
    const { response } = error;
    const { status, data } = response || {};
    if (status === 451) {
      window.location.href = data.result.redirectUrl;
      return new Promise(() => {});
    }
    // 请求超时的之后，抛出 error.code = ECONNABORTED的错误..错误信息是 timeout of  xxx ms exceeded
    if (
      error.code === 'ECONNABORTED' &&
      error.message.indexOf('timeout') !== -1
    ) {
      return Promise.resolve({
        data: { resultMsg: 'Timeout, please try again.' },
      });
    }

    return Promise.reject(error);
  }
);

export const request = (url: string, options: AxiosRequestConfig) => {
  const urlN =
    url.indexOf('http') === 0
      ? url
      : `${window.location.origin}/${GLOBAL_CONST.BASE_URL}${url}`;

  const defaultOptions = {
    credentials: 'include',
  };

  const newOptions: any = { ...defaultOptions, ...options };

  // uaa那边奇怪的转化，把body转data，有魔法先放着了
  if (
    typeof newOptions.body !== 'string' &&
    !(newOptions.body instanceof FormData)
  ) {
    newOptions.body = JSON.stringify(newOptions.body);
  }
  newOptions.data = newOptions.body;
  delete newOptions.body;

  // 不处理异常情况标识
  // const hasError = newOptions?.hasError;
  // delete newOptions.hasError;

  const config = {
    url: urlN,
    ...newOptions,
  };
  return instance(config);
};

export const requestWrapper = (url: string, options?: IOption): any => {
  const hasError = options?.hasError;
  delete options?.hasError;

  return request(url, {
    ...options,
  })
    .then((r: any) => {
      const { data: response } = r;

      // 正常返回内容
      return response;
    })
    .catch(({ response } = {}) => {
      // 当需要抛出异常情况处理时
      if (hasError) return response;

      return null;
    });
};

export { axios, instance };
