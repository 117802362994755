import { Divider } from 'antd';
import classNames from 'classnames';
import React from 'react';

// import icAli from '@/assets/images/ic-ali.png';
// import icBili from '@/assets/images/ic-bili.png';
// import icCsdn from '@/assets/images/ic-csdn.png';
// import icG from '@/assets/images/ic-g.png';
// import icGithub from '@/assets/images/ic-github.png';
import { FooterConfig, LinkItem } from '@/type/config';

import style from './index.less';

interface LayoutFooterProps {
  logo: string;
}
const LayoutFooter = (props: LayoutFooterProps) => {
  const { logo } = props;
  const handleItemClick = (item: LinkItem) => {
    if (item.isBlank) {
      window.open(item.path);
    } else {
      window.location.href = item.path;
    }
  };
  const { FOOTER_CONFIG } = window as any;
  const { linkList, supportLogo, description, footerText, footerLink, footerImage } = FOOTER_CONFIG as FooterConfig;

  const renderLogo = () => {
    // if (supportLogo?.length === 0) {
    //   return (
    //     <>
    //       <img src={icAli} alt="" height="20" />
    //       <img src={icBili} alt="" height="18" />
    //       <img src={icCsdn} alt="" height="28" />
    //       <img src={icG} alt="" height="28" />
    //       <img src={icGithub} alt="" height="28" />
    //     </>
    //   );
    // }

    return (
      <>
        {supportLogo.map(item => <img key={item} src={item} alt="" height="20" />)}
      </>
    );
  };

  let data = Object.keys(linkList);
  const freeTrialOpen = (window as any).FREE_TRIAL_CONFIG?.open;
  if (!freeTrialOpen) {
    data = data.filter(i => i !== 'Promotions');
  }

  return (
    <div className={style.layoutFooter}>
      <div className={classNames('contain', 'layoutFooter-content')}>
        <div className="layoutFooter-content-left">
          {data.map(item => (
            <div className="layoutFooter-content-item" key={item}>
              <div className="layoutFooter-content-item-title"> {item}</div>
              <div>
                {(linkList)[item].map((itemChild: { name: string; path: string }) => (
                  <div onClick={() => handleItemClick(itemChild)} className="layoutFooter-content-item-content" key={itemChild.name}>{itemChild.name}</div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="layoutFooter-content-right">
          <img style={{ objectFit: 'contain' }} src={logo} alt="" width="66" height="30" />
          <div className="layoutFooter-content-right-content">
            {description || 'A comprehensive suite of global cloud computing services to power your business.'}
          </div>
          <div className="layoutFooter-content-right-logoList">
            {renderLogo()}
          </div>
        </div>
      </div>
      <div className={classNames('contain', 'layoutFooter-divider')}>
        <Divider />
      </div>

      <div className={classNames('layoutFooter-footer', 'contain')}>
        <div className="layoutFooter-left">
          <div className="layoutFooter-link">
            {footerLink.map(item => {
              return <a key={item.name} href={item.path} target="_blank" rel="noreferrer">{item.name}</a>;
            })}
          </div>
          <div className="layoutFooter-text">
            {footerText.map(item => {
              return <div key={item}>{item}</div>;
            })}
          </div>
        </div>
        <div className="layoutFooter-right">
          {
              footerImage.map(item => {
                const content = <img key={item.imageUrl} src={item.imageUrl} alt="" />;
                if (item.path) {
                  return <a href={item.path} target="_blank" rel="noreferrer">{content}</a>;
                }
                return content;
              })
            }
        </div>
      </div>
    </div>
  );
};
export default LayoutFooter;
