import { GetAllProductRes, LoggedData, LoggedParmas, queryAreaCodeRes } from '@/type';
import { requestWrapper } from '@/utils/service';

export async function postLogin(params: LoggedParmas) {
  return requestWrapper<LoggedData>('/prod-customer/user/v1/auth/postLogin', {
    params,
  });
}


/**
 * 退出登录
 */
export async function sendLogout() {
  return requestWrapper('/prod-customer/user/v1/auth/logout?callCmp=true', {
    method: 'GET',
  });
}

/**
 * 获取配置
 */
export async function queryTheme() {
  return requestWrapper('/prod-customer/config/v1/theme/queryAll', {
    method: 'GET',
  });
}

export async function queryDocumentList(params: { searchCondition: string; pageBean: { currentPage: number; pageSize: number } }) {
  return requestWrapper('/apsara/customer/user/v1/search/query', {
    method: 'POST',
    body: params,
  });
}

/**
 * 客户留资
 */
export async function sendConsultInfo(params: { content: string }) {
  return requestWrapper('/prod-customer/ticket/v1/contactUs', {
    method: 'POST',
    body: params,
  });
}
// 查询地区代码
export async function queryAreaCode() {
  return requestWrapper<queryAreaCodeRes[]>('/prod-customer/user/v1/dict/countryAndState', {
    method: 'GET',
  });
}

// 获取所有产品
export async function getAllProduct() {
  return requestWrapper<GetAllProductRes>('/prod-customer/catalog/v1/getAllProduct', {
    method: 'POST',
    body: { productOfferType: 'OFF' },
  });
}

// 查询region
export async function queryRegion() {
  return requestWrapper<any[]>('/prod-customer/region/v1/query', {
    method: 'GET',
  });
}

export const queryConsoleRole = (params: {regionCode: string}) => {
  return requestWrapper('/prod-customer/catalog/v1/queryConsoleRole', {
    method: 'GET',
    params,
  });
};

