import { useEffect, useState } from 'react';

import styles from './index.less';

const hanldeHeaderAnimate = (dom: HTMLElement, scroller: HTMLElement, tabStart: number) => {
  const { scrollTop } = scroller
  if (scrollTop > tabStart && scrollTop < tabStart + 60) {
    dom.style.top = `-${scrollTop - tabStart}px`;
  } else if (scrollTop < tabStart) {
    dom.style.top = '0';
  } else {
    dom.style.top = '-60px';
  }
};
const checkPercent = (percent: number) => {
  if (percent > 1) return 1;
  return percent;
};

type changeTabsWidthProps = {
  minWidth: number;
  tabStart: number;
  tabEnd: number;
  scroller: HTMLElement;
  tabsDom: HTMLElement;
}

const changeTabsWidth = ({ minWidth, tabStart, tabEnd, scroller, tabsDom }: changeTabsWidthProps) => {
  const { scrollTop } = scroller;
  const width = window.innerWidth;
  let baseWidthPercent = checkPercent(minWidth / width);
  if (baseWidthPercent > 1) baseWidthPercent = 1;
  if (scrollTop < tabStart) {
    tabsDom.style.width = `${baseWidthPercent * 100}%`;
  }

  if (minWidth < width && scrollTop > tabStart && scrollTop < tabEnd) {
    const percent = checkPercent((scroller.scrollTop - tabStart) / (tabEnd - tabStart) * (1 - baseWidthPercent) + baseWidthPercent);

    tabsDom.style.width = `${percent * 100}%`;
    tabsDom.style.top = 'initial';
  }
  if (scrollTop >= tabEnd) {
    tabsDom.classList.add(styles['tabs-fixed']);
    // 调整padding
    // tabsDom.style.paddingLeft = '0px';
    // tabsDom.style.paddingRight = '0px';
  } else {
    tabsDom.classList.remove(styles['tabs-fixed']);
    // 调整padding
    // tabsDom.style.paddingLeft = basePadding;
    // tabsDom.style.paddingRight = basePadding;
  }
}

const useTabsAnimate = (id: string, options?: { start?: number, end?: number }) => {
  const start = options?.start || 150;// 动画开始高度
  const headerStart = start;
  const tabStart = start;
  const tabEnd = options?.end || 450;// 动画结束高度
  const [minWidth, setMinWidth] = useState(1200);

  const handleResize = () => {
    const tabsDom = document.getElementById(id);
    const scroller = document.getElementById('scroll-container-id');
    if (!tabsDom) return;
    if (!scroller) return;
    const newMinWidth=parseInt(getComputedStyle(tabsDom).minWidth)
    setMinWidth(newMinWidth)
    changeTabsWidth({ minWidth:newMinWidth, tabStart, tabEnd, tabsDom, scroller })
  }

  useEffect(() => {
    const tabsDom = document.getElementById(id);
    if (tabsDom) {
      setMinWidth(parseInt(getComputedStyle(tabsDom).minWidth))
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [id])

  useEffect(() => {
    return () => {
      // 防止滚到底部后切换内容导致header隐藏
      const header = document.getElementById('layout-header');
      if (header) {
        header.style.top = '0';
      }
    }
  }, [])

  useEffect(() => {


    const scroller = document.getElementById('scroll-container-id');
    const header = document.getElementById('layout-header');
    const tabsDom = document.getElementById(id);

    if (!scroller) return () => { };
    if (!tabsDom) return () => { };
    if (!header) return () => { };
    if (!tabsDom) return () => { };

    const handleScroll = () => {
      requestAnimationFrame(() => {
        hanldeHeaderAnimate(header, scroller, headerStart);

        changeTabsWidth({ minWidth, tabStart, tabEnd, tabsDom, scroller })

      });
    };
    scroller?.addEventListener('scroll', handleScroll);
    return () => {
      scroller?.removeEventListener('scroll', handleScroll);

    };
  }, [id, options?.start, options?.end, minWidth]);
};
export default useTabsAnimate;
