import { useDocumentVisibility, useRequest, useUpdateEffect } from 'ahooks';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useToConsole from '@/hooks/useToConsole';
import { postLogin } from '@/services';
import { BaseConfig } from '@/type/config';

import AvatarDropdown from './AvatarDropdown';
import style from './index.less';
import LanguageDropdown from './LanguageDropdown';
import Products from './Products';
import Solution from './Solution';


interface LayoutHeaderProps {
  logo: string;
  avatar: string;
}

const LayoutHeader = (props: LayoutHeaderProps) => {
  const { bannerText } = (window as any).BASECONFIG as BaseConfig;
  const {
    avatar,
    logo,
  } = props;
  const [userInfo, setUserInfo] = useState<any>({});
  const documentVisibility = useDocumentVisibility();
  const navigate = useNavigate();
  const { toConsole } = useToConsole();

  const { run: loadLogIn } = useRequest(async () => {
    const res = await postLogin({ rootMenu: 'work_bench' });
    setUserInfo(res?.result || {});
    if (res.resultCode === '0') {
      localStorage.setItem('checkIsLogin', 'true');
    } else {
      localStorage.setItem('checkIsLogin', 'false');
    }
  }, { pollingInterval: 20000 });

  useUpdateEffect(() => {
    if (documentVisibility === 'visible') {
      loadLogIn();
    }
  }, [documentVisibility]);

  // 处理头像登录情况
  const renderAvatar = () => {
    return userInfo?.username ? (
      <AvatarDropdown avatar={avatar} userInfo={userInfo} loadLogIn={loadLogIn} />
    ) : (
      <div
        className={classNames('header-right-logIn', 'animation-btn', 'cursor-pointer')}
        onClick={() => {
          toConsole({ targetRoute: `/login?redirect=${encodeURIComponent(`${window.location.protocol}//${window.location.host}/homepage`)}` });
        }}
      >
        <span>{bannerText?.logIn}</span>
      </div>
    );
  };

  return (
    <div id="layout-header" className={classNames(style.header, 'flex')}>
      <Link className="header-logo" to="/">
        <img src={logo} alt="" className="header-left-title" />
      </Link>
      <Products bannerText={bannerText?.products} />
      <div className="header-left-title" onClick={() => navigate('/pricing')}>{bannerText?.pricing}</div>
      {(window as any).MARKETPLACE_CONFIG.open && <Link to="/marketplace"><div className="header-left-title">Marketplace</div></Link>}
      <Link to="/support"><div className="header-left-title">{bannerText?.support}</div></Link>
      {(window as any).DOCUMENT_CENTER.open && <Link to="/documentCenter"><div className="header-left-title">{bannerText?.documentation}</div></Link>}
      {(window as any).SOLUTION.open && <Solution bannerText={bannerText?.solution ?? 'Solution'} />}
      <div className={classNames('header-right', 'flex')}>
        <LanguageDropdown bannerText={bannerText?.console} />
        {renderAvatar()}
      </div>
    </div>

  );
};
export default LayoutHeader;
