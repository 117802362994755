import { Form, Input, Select, Table } from 'antd';
import React, { useEffect, useRef } from 'react';

import searchIcon from '@/assets/images/search-icon.png';
import type { Tabs } from '@/type/config';

import styles from './ProductTable.less';

export interface ProductTableProps extends Tabs {
  id: string;
}
function compareFunction(a: string | number, b: string | number): number {
  // 判断是否为数字
  const isNumber = (value: string | number): value is number => typeof value === 'number' && !Number.isNaN(value);

  // 将可以转换为数字的字符串转换为数字
  const convertToNumber = (value: string | number) => {
    let tempValue = value;
    if (isNumber(value)) return value;

    tempValue = value.replace(',', '');

    const parsedValue = parseFloat(tempValue);
    return Number.isNaN(parsedValue) ? tempValue : parsedValue;
  };
  const convertedA = convertToNumber(a);
  const convertedB = convertToNumber(b);
  // 检查类型并进行排序
  if (isNumber(convertedA) && isNumber(convertedB)) {
    return convertedA - convertedB; // 数字按照大小排序
  } if (isNumber(convertedA)) {
    return -1; // 数字在前，字符串在后
  } if (isNumber(convertedB)) {
    return 1; // 数字在前，字符串在后
  }

  return String(convertedA).localeCompare(String(convertedB)); // 字符串按照字母顺序排序
}
const ProductTable = (props: ProductTableProps) => {
  const [form] = Form.useForm();
  const { id, category, type, subscription, payAsYouGo } = props;
  const billingMethods = [subscription?.length && 'Subscription', payAsYouGo?.length && 'Pay-As-You-Go'].filter(item => item) as Array<string>;
  const showCategory = category && category.length !== 0;
  const showType = type && type.length !== 0;
  // const { billingMethod: currentBillingMethod,
  //   category: currentCategory,
  //   type: currentType
  // } = form.getFieldsValue()
  const currentBillingMethod = Form.useWatch('billingMethod', form);
  const currentCategory = Form.useWatch('category', form);
  const currentType = Form.useWatch('type', form);
  const currentFilter = Form.useWatch('filter', form);
  const tableRef = useRef<HTMLDivElement>(null);
  let currentData;
  let filterCategory:string[]=[];
  let filterType:string[]=[]
  if (currentBillingMethod === 'Subscription') {
    currentData = category || type ?
      subscription?.find(item => (item.category && item.category === currentCategory) || (item.type && item.type === currentType))
      :
      subscription?.[0];
    filterCategory=Array.from(new Set(subscription?.map(item=>item.category))).filter(Boolean) as string[]
    filterType=Array.from(new Set(subscription?.map(item=>item.type))).filter(Boolean) as string[]
  } else {
    currentData = category || type ?
      payAsYouGo?.find(item => (item.category && item.category === currentCategory) || (item.type && item.type === currentType))
      :
      payAsYouGo?.[0];
      filterCategory=Array.from(new Set(payAsYouGo?.map(item=>item.category))).filter(Boolean) as string[]
      filterType=Array.from(new Set(payAsYouGo?.map(item=>item.type))).filter(Boolean) as string[]
  }
  const filter = currentData?.filter;
  const dataSource = currentData?.dataSource;
  const columns = (currentData?.columns || []).map((item: any) => {
    if (!item.sorter) {
      return {
        ...item,
        sorter: {
          compare: (a: any, b: any) => compareFunction(a[item.dataIndex], b[item.dataIndex]),
        },
      };
    }
    return item;
  });
  const filterDataSource = dataSource?.filter(item => {
    let flag = true;
    filter?.forEach(filterItem => {
      const { dataIndex, type: filterType } = filterItem;
      if (currentFilter?.[dataIndex]) {
        if (filterType === 'Input' && item[dataIndex].indexOf(currentFilter[dataIndex]) === -1) {
          flag = false;
        } else if (filterType === 'Select' && item[dataIndex] !== currentFilter[dataIndex]) {
          flag = false;
        }
      }
    });
    return flag;
  }).map((item, index) => { return { ...item, key: index }; });
  
  useEffect(()=>{
    const checkExist=(current:any,arr:any[],key:string)=>{
      if(arr.length===0){
        form.resetFields([key]);
         return;
      }
      // console.log(current,arr,key)
      if(arr.indexOf(current)===-1){
        //不加这个会导致ResizeObserver loop completed with undelivered notification
        requestAnimationFrame(()=>{
          form.setFieldValue(key,arr?.[0])
        })
        
      }
    }
    checkExist(currentFilter,filterCategory,'category')
    checkExist(currentType,filterType,'type')
  })
  useEffect(() => {
    // console.log(sorters)
    const sorters = Array.from(tableRef.current?.getElementsByClassName('ant-table-column-sorters') || []) as Array<HTMLElement>;
    sorters.forEach(sorter => {
      const textAlign = getComputedStyle(sorter).getPropertyValue('text-align');
      // console.log(textAlign,sorter)
      if (textAlign === 'right') {
        sorter.style.justifyContent = 'flex-end';
      }
    });
  });

  const renderSummary = () => {
    return filterDataSource?.length !== 0 ? (
      <Table.Summary.Row>
        <Table.Summary.Cell className={styles['table-end-cell']} colSpan={columns.length} index={0}>
          <div className={styles['table-end-div']}>No more records</div>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    )
      :
      <React.Fragment />;
  };

  return (
    <div id={id} className={styles.table}>
      <Form
        initialValues={{
          billingMethod: billingMethods[0],
          category: category?.[0],
          type: type?.[0],
        }}
        colon={false}
        form={form}
        labelCol={{ span: 2 }}
        labelAlign="left"
      >
        {
          billingMethods.length !== 0 && (
            <Form.Item name="billingMethod" label="Billing Method">
              <div className={styles.row}>
                {
                  billingMethods.map(item => {
                    return (
                      <div
                        key={item}
                        className={currentBillingMethod === item ? styles['active-box'] : ''}
                        onClick={() => {
                          form.setFieldsValue({ billingMethod: item });
                        }}
                      >{item}
                      </div>
                    );
                  })
                }
              </div>
            </Form.Item>
          )
        }
        {
          showCategory && (
            <Form.Item name="category" label="Category">
              <div className={styles.row}>
                {
                  filterCategory?.map(item => {
                    return (
                      <div
                        onClick={() => {
                          form.setFieldsValue({ category: item });
                        }}
                        key={item}
                        className={currentCategory === item ? styles['active-box'] : ''}
                      >{item}
                      </div>
                    );
                  })
                }
              </div>
            </Form.Item>
          )
        }
        {
          showType && (
            <Form.Item name="type" label="Type">
              <div className={styles.row}>
                {
                  filterType?.map(item => {
                    return (
                      <div
                        onClick={() => {
                          form.setFieldsValue({ type: item });
                        }}
                        key={item}
                        className={currentType === item ? styles['active-box'] : ''}
                      >{item}
                      </div>
                    );
                  })
                }
              </div>
            </Form.Item>
          )
        }
        {
          filter && filter.length && (
            <Form.Item label="Filter">
              <div className={styles.filter}>
                {
                  filter?.map(item => {
                    const { type: filterType, dataIndex, placeholder } = item;
                    if (filterType === 'Select') {
                      const options = Array.from(new Set(dataSource?.map(dataItem => dataItem[dataIndex]))).sort(compareFunction).map(dataItem => {
                        return {
                          label: dataItem,
                          value: dataItem,
                        };
                      });
                      return (
                        <Form.Item key={item.dataIndex} name={['filter', dataIndex]}>
                          <Select allowClear placeholder={placeholder} options={options} />
                        </Form.Item>
                      );
                    }
                    if (filterType === 'Input') {
                      return (
                        <Form.Item key={item.dataIndex} name={['filter', item.dataIndex]}>
                          <Input prefix={<img className={styles['filter-img']} src={searchIcon} alt="" />} placeholder={placeholder} />
                        </Form.Item>
                      );
                    }
                    return <></>;
                  })
                }
              </div>
            </Form.Item>
          )
        }
      </Form>
      <Table
        ref={tableRef}
        scroll={{ y: 523 }}
        key="key"
        pagination={false}
        columns={columns}
        dataSource={filterDataSource}
        summary={renderSummary}
      />

    </div>
  );
};
export default ProductTable;
