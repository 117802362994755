import { Space } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import icEnglish from '@/assets/images/ic-english.png';
import icPulldown from '@/assets/svg/ic-pulldown.svg';
import useToConsole from '@/hooks/useToConsole';
import type { BaseConfig } from '@/type/config';

import styles from './LanguageDropdown.less';

interface DropdownProps {
  // userInfo: { username: string }
  bannerText: string;
}
type SplitArrayColumn = <T>(column: number, array: T[]) => T[][]
const splitArrayColumn: SplitArrayColumn = (column, array) => {
  const columns = new Array(column);
  for (let i = 0; i < columns.length; i += 1) {
    columns[i] = [];
  }
  array.forEach((item, index) => {
    columns[index % column].push(item);
  });
  return columns;
};

const LanguageDropdown = (props: DropdownProps) => {
  const { bannerText } = props;
  const [visible, setVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const BASECONFIG = (window as any).BASECONFIG as BaseConfig;
  const language = BASECONFIG?.language;
  const { toConsole } = useToConsole();
  const columns = splitArrayColumn(2, language || []);
  const handleMouse = (isToVisible: boolean) => {
    if (visible !== isToVisible) setVisible(isToVisible);
  };

  const handleClick = (url: string) => {
    if (!url) return;
    window.location.href = url;
  };
  const currentLanguage = language?.find(item => item.isCurrent);

  useEffect(() => {
    const dom = dropdownRef.current;
    if (!dom) return;
    if (visible) {
      dom.style.height = 'auto';
      const { height } = dom.getBoundingClientRect();
      dom.style.height = '0px';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { offsetTop } = dom;// 强制浏览器重排
      dom.style.height = `${height}px`;
    } else {
      dom.style.height = '0px';
    }
  }, [visible]);

  return (
    <div onMouseLeave={() => handleMouse(false)} className={styles.languageDropdown}>
      <div onMouseEnter={() => handleMouse(true)} className={classNames(visible && styles.language, 'header-right-title', 'flex', 'flex-center')}>
        <Space>
          <div className={styles.icon}>
            <img src={icEnglish} alt="" width="16" height="16" />
          </div>

          <span>{currentLanguage?.title || 'English'}</span>
          <div className={classNames(styles.icon, styles.arrow)}>
            <img src={icPulldown} alt="" width="12" />
          </div>

        </Space>
      </div>
      <div
        className={classNames(styles.textHover, 'header-right-title', 'cursor-pointer')}
        onClick={() => {
          toConsole({ isBlank: true });
          // window.open(`${window.location.origin}${userInfo?.username ? '/work_bench/dashboard' : '/login'}`);
        }}
      >
        {bannerText}
      </div>
      <div ref={dropdownRef} className={styles.dropdown}>
        <div>
          {columns.map((item, index) => {
            return (
              <div key={index} className={styles['dropdown-contain']}>
                {
              item.map(cItem => {
                return (
                  <div key={cItem.title} onClick={() => handleClick(cItem.url)} className={classNames(styles.content, cItem.isCurrent && styles.active)}>
                    {cItem.title}
                  </div>
                );
              })
            }
              </div>
            );
          })}
        </div>

      </div>
    </div>
  );
};

export default LanguageDropdown;
