import { Dropdown, Layout, Menu, Space } from 'antd';
import classNames from 'classnames';
import React, { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// import productCategory from '../../../public/config/productCategory.js';
import icDropProduct from '@/assets/images/ic-drop-product.png';
import imgBgDropdown from '@/assets/images/img-bg-dropdown.png';
import imgDropdownShape from '@/assets/images/img-dropdown-shape.png';
import icPulldown from '@/assets/svg/ic-pulldown.svg';
import style from './Products.less';
import { useRequest } from 'ahooks';

const { Sider, Content } = Layout;

interface Category {
  Product: string;
  Description: string;
  flag: string | undefined;
  type: string;
  headerIcon: string;
  headerIconHover: string;
}
interface TabItem {
  key: string;
  title: string;
}

const flagMap = {
  hot: 'Hot',
  new: 'New',
  coming: 'Coming soon',
};

const fetchImage = (url: string) => {
  return new Promise<string>(async (res, rej) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        res(reader.result)
      } else {
        res(url)
      }
    };
    reader.readAsDataURL(blob);
  })
}

const ProductItem = (props: { data: Category, setVisible: (visible: boolean) => void }) => {
  const navigate = useNavigate();
  const { data, setVisible } = props
  const [isHover, setIsHover] = useState(false)
  return <div
    className={classNames('downDrawer-content-item', 'cursor-pointer')}
    key={data.Product}
    onClick={() => {
      setVisible(false);
      navigate(`/products?type=${data.type}`);
    }}
    onMouseEnter={() => setIsHover(true)}
    onMouseLeave={() => setIsHover(false)}
  >
    <img src={data[isHover ? 'headerIconHover' : 'headerIcon']} alt="" width="24" height="24" />
    <div className="downDrawer-content-item-list">
      <div className="downDrawer-content-item-title">
        {data.Product}
        {data.flag && (
          <span className={`downDrawer-content-item-${data.flag}`}>
            {flagMap[data.flag as keyof typeof flagMap]}
          </span>
        )}
      </div>
      <div className="downDrawer-content-item-content">{data.Description}</div>
    </div>
  </div>
}


const DownDrawer = forwardRef((props: { setVisible: (visible: boolean) => void }) => {
  const { setVisible } = props;

  const { productCategory = {} } = (window as any)?.PRODUCT_CATEGORY;
  const TabsJson: TabItem[] = [];
  for (const key in productCategory) {
    if (productCategory?.hasOwnProperty(key)) {
      TabsJson.push({ key, title: key.replace(/([a-z])([A-Z])/g, '$1 $2') });
    }
  }
  const [selectMenuKey, setSelectMenuKey] = useState(TabsJson[0].key || '');
  useEffect(() => {
    Object.keys(productCategory).forEach((item: string) => {
      const element = document?.getElementById(item) || { onmouseenter: '' };
      element.onmouseenter = function () {
        setSelectMenuKey(item);
      };
    });
  }, [productCategory]);
  const { data: dropdownBg } = useRequest(async () => {
    const base64 = await fetchImage(imgBgDropdown);
    return base64
  })

  return (
    <div className={style.downDrawer}>
      <Layout>
        <Sider style={{ background: `url('${dropdownBg}') 0%0%/cover` }}>
          <Menu
            selectedKeys={[selectMenuKey]}
            onClick={({ key }) => {
              setSelectMenuKey(key);
            }}
            items={TabsJson.map(item => ({
              key: item.key,
              label: <span className="nav-text" id={item.key}><span>{item.title || item.key}</span></span>,
            }))}
          />
        </Sider>

        <Content className={classNames('downDrawer-content-bg', 'position-relative')}>
          {TabsJson.map((item: any) => (
            <div
              className={classNames(
                'downDrawer-content',
                selectMenuKey === item.key ? 'downDrawer-content-selectMenu' : '')}
              key={item.key}
            >
              <div className="downDrawer-content-right-title">
                <img src={imgDropdownShape} alt="" width="13" />{item.title || item.key}
                <img src={imgDropdownShape} alt="" width="13" />
                <hr />
              </div>
              {(productCategory as any)?.[item.key]?.list?.map((itemChild: Category) => (
                <ProductItem data={itemChild} setVisible={setVisible} key={itemChild.Product}></ProductItem>
              ))}
            </div>
          ))}

        </Content>

      </Layout>

    </div>
  );
});



const Products = ({ bannerText }: { bannerText: string }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Dropdown
      dropdownRender={() => <DownDrawer setVisible={setVisible} />}
      open={visible}
      onOpenChange={flag => {
        setVisible(flag);
      }}
      overlayStyle={{ left: 0 }}
      forceRender
    >
      <div onClick={e => e.preventDefault()} className={style.downSvg}>
        <div className="downTitle">
          <Space size={2} className={visible ? 'space' : ''}>
            {bannerText}
            <img
              src={icPulldown}
              alt=""
              width="20"
              className={visible ? 'img-filter' : ''}
            />
          </Space>
        </div>
      </div>
    </Dropdown>
  );
};

export default Products;
