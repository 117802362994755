

import type { AnchorProps } from 'antd';
import { Button, ConfigProvider } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import overviewPng from '@/assets/images/overview-title.png';
import payMethodAsYouGoSvg from '@/assets/svg/pay-method-asyougo.svg';
import payMethodSubSvg from '@/assets/svg/pay-method-sub.svg';
import Banner from '@/components/Banner';
import Features from '@/components/Features';
import SecondBtn from '@/components/SecondBtn';
import Tabs from '@/components/Tabs';
import useSlideIn from '@/hooks/useSlideIn';
import moveStyles from '@/hooks/useSlideIn/index.less';
import useTabsAnimate from '@/hooks/useTabsAnimate';
import useToConsole from '@/hooks/useToConsole';
import useToPurchase from '@/hooks/useToPurchase';
import type { ContactConfig, Product } from '@/type/config';
import type ProductConfig from '@/type/config';
import type { ProductData } from '@/type/index';
import { jumpToConsole, jumpToPurchase } from '@/utils';
import { scrollToElement } from '@/utils/animateUtil';

import Benefits from './components/Benefits';
import Price from './components/Price';
import styles from './index.less';

interface ProductProps extends Omit<ProductConfig, 'products'> {
  product: Product;
  productData?: ProductData;
  regionList?: any[];
  roleMap?: any;
  roleMapLoading: boolean;
  productDataLoading: boolean;
  regionListLoading: boolean;
  flag?: string;
}

const defaultCommon = {
  featureTitle: 'Features',
  featureDes: 'We offers the best performance compared to general-purpose from any of the leading public cloud vendors.',
  benefitsTitle: 'Benefits',
  benefitsDes: 'these recommendations can help you optimize your Compute Engine usage and benefits.',
  choosePay: {
    title: 'Choose How You Pay',
    description: 'We provides flexible purchasing options. Based on your needs, you can select between two options.',
    subscriptionTitle: 'Subscription',
    subscriptionDes: 'Subscription fees are lower on average than that of Pay-As-You-Go, and suited for those requiring long-term resources at reduced cost.',
    payAsYouGoTitle: 'Pay-As-You-Go',
    payAsYouGoDes: 'Pay-As-You-Go Bills you for the exact amount of resources you use. Activate or release resources at any time with no hardware or maintenance costs.',
    subscriptionUrl: payMethodSubSvg,
    payAsYouGoUrl: payMethodAsYouGoSvg,
  },
};

const Products = (props: ProductProps) => {
  const {
    product,
    productTabs,
    contactBtnText,
    common = defaultCommon,
    productData,
    regionList,
    roleMap,
    productDataLoading,
    roleMapLoading,
    regionListLoading,
    flag,
  } = props;
  const { contactFormUrl }: ContactConfig = (window as any).CONTACT_CONFIG[0];
  const isComing = flag === 'coming';
  const tabsId = 'product-tabs';
  useTabsAnimate(tabsId);
  useSlideIn(moveStyles['slidein-item']);

  const themeColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
  const {
    isFreeTrial,
    bannerIsVideo,
    primaryColor,
    secondaryColor,
    secondaryBtnBackground,
    productName,
    description,
    isPay,
    overview,
    features,
    benefits,
    billingSubscription,
    billingPayAsYouGo,
    bannerUrl,
    overviewUrl,
    bannerBackgroundColor,
    region = [],
    buyText,
    documentUrl,
    type,
    productPurchaseFlag,
  } = product;
  const tabs = productTabs.map(item => {
    switch (item.key) {
      case 'part-1':
        return {
          key: 'part-1',
          href: '#product-overview',
          title: item.title,
        };
      case 'part-2':
        return {
          key: 'part-2',
          href: '#product-features',
          title: item.title,
        };
      case 'part-3':
        return {
          key: 'part-3',
          href: '#product-benefits',
          title: item.title,
        };
      case 'part-4':
        if (isComing) return undefined;
        return {
          key: 'part-4',
          href: '#product-pay',
          title: item.title,
        };
      case 'part-5':
        return {
          key: 'part-5',
          href: documentUrl || '/homepage/documentCenter',
          target: '_blank',
          title: (
            <div className={styles['doc-contain']}>
              <span>{item.title}</span>
              <div className={styles['doc-icon']}>
                <div className={styles['doc-icon-content']} />
              </div>
            </div>
          ),
        };
      default:
        return {
          key: 'part-1',
          href: '#product-overview',
          title: item.title,
        };
    }
  }).filter(Boolean) as AnchorProps['items'];


  const { toConsole } = useToConsole();
  const handleBuy = () => {
    const isLogin = localStorage.getItem('checkIsLogin') === 'true';
    if (!productData || !regionList?.length || !isLogin) {
      toConsole({ isBlank: true });
      return;
    }

    if (productPurchaseFlag) {
      jumpToPurchase(productData, regionList, toConsole);
    } else {
      jumpToConsole(productData, roleMap, toConsole);
    }
  };

  const handleClickContact = () => {
    window.open(contactFormUrl || '/homepage/contactNow');
  };

  const isLogin = localStorage.getItem('checkIsLogin') === 'true';
  const payLoading = (productDataLoading || regionListLoading) && isLogin;
  const consoleLoading = (productDataLoading || roleMapLoading) && isLogin;
  const handleBuyLoading = isPay ? payLoading : consoleLoading;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: themeColor,
        },
      }}
    >
      <div className={styles.container}>
        <Banner bannerIsVideo={bannerIsVideo} bannerUrl={bannerUrl} bannerBackgroundColor={bannerBackgroundColor}>
          <div className={classNames(styles.banner, 'contain')}>
            <div className={styles['banner-title']} style={{ color: primaryColor }}>
              {productName}
            </div>
            <div className={styles['banner-description']} style={{ color: secondaryColor }}>{description}</div>
            {!isComing && <Button loading={handleBuyLoading} onClick={handleBuy} type="primary" className={classNames(styles['banner-btn'], 'animation-btn-always')}>{buyText || 'Buy Now'}</Button>}
            {(window as any).FREE_TRIAL_CONFIG?.open && isFreeTrial && <SecondBtn style={{ backgroundColor: secondaryBtnBackground }} onClick={() => window.open('/homepage/freeTrial')} className={classNames(styles['banner-btn'], 'second-btn')}>Free Trial</SecondBtn>}
            <SecondBtn onClick={handleClickContact} className={classNames(styles['banner-btn'])} style={{ backgroundColor: secondaryBtnBackground }}>{contactBtnText}</SecondBtn>
          </div>
          <Tabs id={tabsId} items={tabs} />
        </Banner>
        <div id="product-overview" className={styles['overview-contain']}>
          <div className={classNames(styles.overview, moveStyles['slidein-item'], 'contain')}>
            <div className={styles['overview-img']}><img src={overviewUrl} alt="" /></div>

            <div className={styles['overview-content']}>
              <div className={styles['overview-content-title']}>
                <img src={overviewPng} alt="" />
                <span>Product Overview</span>
                <img src={overviewPng} alt="" />
              </div>
              {
                overview.map(item => {
                  return <span key={item}>{item}</span>;
                })
              }
            </div>
          </div>
        </div>

        <div id="product-features" className={styles['slidein-contain']}>
          <div className={classNames(styles.features, moveStyles['slidein-item'])}>
            <div className={classNames(styles['mg-auto'], 'contain')}>
              <div className={styles['features-title']}>{common?.featureTitle}</div>
              <div className={styles['features-description']}>{common?.featureDes}</div>
              <Features items={features} />
            </div>

          </div>
        </div>

        <div id="product-benefits" className={styles['slidein-contain']}>
          <div className={classNames(styles.benefits, moveStyles['slidein-item'])}>
            <Benefits title={common.benefitsTitle} desc={common.benefitsDes} benefits={benefits} />

          </div>
        </div>
        {
          !isComing && <Price choosePay={common?.choosePay} region={region} slideinClass={moveStyles['slidein-item']} type={type} isPay={isPay} billingSubscription={billingSubscription} billingPayAsYouGo={billingPayAsYouGo} />
        }

      </div>
    </ConfigProvider>
  );
};
const ProductsIntercept = () => {
  const [params] = useSearchParams();
  const scrollToPricing = params.get('scrollToPricing');
  const type = params.get('type');

  const { PRODUCT_CONFIG = {} } = window as any;
  const { products, productTabs, contactBtnText, common } = PRODUCT_CONFIG as ProductConfig;
  const product = products.find((item: Product) => item.type === type) as Product | undefined;

  const { productCategory = {} } = (window as any).PRODUCT_CATEGORY;

  const flag = useMemo(() => {
    const keys = Object.keys(productCategory);
    for (let i = 0; i < keys.length; i += 1) {
      const target = productCategory[keys[i]].list.find((p: any) => p.type === type);
      if (target) {
        return target.flag;
      }
    }
    return undefined;
  }, [productCategory, type]);

  if (!product || !type) {
    return <></>;
  }

  useEffect(() => {
    // 付费产品滚动到价格表，免费产品滚动到免费图
    const productTableDom = document.getElementById('product-pay');
    if (scrollToPricing) {
      scrollToElement(productTableDom as HTMLDivElement, 62, 'auto');
    }
  }, [scrollToPricing]);
  const {
    productData,
    productDataLoading,
    regionList,
    regionListLoading,
    roleMap,
    roleMapLoading,
  } = useToPurchase({ type: product.type, offerCode: product.offerCode });
  const navigate = useNavigate();

  useEffect(() => {
    if (!product) {
      navigate('/404');
    }
  }, [product, navigate]);


  return (
    <Products
      flag={flag}
      common={common}
      contactBtnText={contactBtnText}
      productTabs={productTabs}
      product={product}
      productData={productData}
      regionList={regionList}
      roleMap={roleMap}
      productDataLoading={productDataLoading}
      regionListLoading={regionListLoading}
      roleMapLoading={roleMapLoading}
    />
  );
};
export default ProductsIntercept;
