export function getElementDistanceToTop(element: HTMLDivElement | null) {
  let tempElement = element;
  let distance = 0;
  while (tempElement) {
    distance += tempElement.offsetTop;
    tempElement = tempElement.offsetParent as HTMLDivElement;
  }
  return distance;
}

export function scrollToElement(element: HTMLDivElement | null, offsetTop = 0, behavior: ScrollBehavior = 'smooth') {
  const scrollBar = document.getElementById('scroll-container-id');
  if (!scrollBar) {
    console.error('id为scroll-container-id的元素不存在');
    return;
  }
  requestAnimationFrame(() => {
    const distanceToTop = getElementDistanceToTop(element);
    scrollBar.scrollTo({ top: distanceToTop - offsetTop, behavior });
  });
}
