import React, { useEffect } from 'react'
import styles from './index.less'
import { Anchor } from 'antd'
import type { AnchorProps } from 'antd'
import classNames from 'classnames'

interface TabsProps {
  id: string;
  items: AnchorProps['items'];
  targetOffset?: number;
  className?:string;
}
const Tabs = (props: TabsProps) => {
  const { items=[], id, targetOffset = 62 ,className} = props
  let newItmes=items.map((item,index)=>{
    if(index===0 || index===items.length-1){
      return {
        ...item,
        title:<div className={styles[`a-content-${index===0?'left':'right'}`]}>{item.title}</div>
      }
    }
    return {
      ...item,
    }
  })
  useEffect(()=>{
    document.getElementById(id)?.style.setProperty('--widthPercent',`${100/items.length}%`)
  },[id,items.length])
  return (
  <div id={id} className={classNames(styles.tabs,className)} >
    <Anchor
      targetOffset={targetOffset}
      getContainer={() => document.getElementById('scroll-container-id') as HTMLElement}
      className={styles['tabs-content']}
      // direction="horizontal"
      items={newItmes}
    />
  </div>
  )
}

export default Tabs