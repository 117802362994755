import {
  axios,
  instance,
  IOption,
  requestWrapper as commonRequest,
} from './serviceConfig';

export interface CommonResult<T> {
  resultCode: string;
  resultMsg: string;
  result: T;
}
export interface ResponsePageBean {
  currentPage: number;
  pageSize: number;
  totalPage: number;
  totalRecord: number;
}
export interface PageResult<T = any> {
  list: T[];
  pageBean: ResponsePageBean;
}

// 用于定制业务端
export const requestWrapper = <T = any>(url: string, options?: IOption): Promise<CommonResult<T>> => {
  return commonRequest(url, {
    ...options,
  });
};
// 比requestWrapper 多一层级的请求兼容处理
export async function requestDataWrapper<T>(url: string, op: IOption) {
  const data = await requestWrapper<T>(url, op);
  if (op.hasError) {
    return data;
  }
  if (data) {
    return { data, success: true };
  }
  return data;
}


export const get = <T = any>(url: string, options?: IOption) => {
  return requestWrapper<T>(url, {
    method: 'get',
    ...options,
  });
};

export const post = <T = any>(url: string, options?: IOption) => {
  return requestWrapper<T>(url, {
    method: 'post',
    ...options,
  });
};

export const deleteMethod = <T = any>(url: string, options?: IOption): Promise<CommonResult<T>> => {
  return requestWrapper(url, {
    method: 'delete',
    ...options,
  });
};

export const put = <T = any>(url: string, options?: IOption): Promise<CommonResult<T>> => {
  return requestWrapper(url, {
    method: 'put',
    ...options,
  });
};
export {
  axios,
  instance,
};
