import { find } from 'lodash';
import moment from 'moment';
import type { ToConsoleProps } from '@/hooks/useToConsole'

import { PlatformEnum, PurchaseOfferCodeReverse } from '@/enum/toPurchase';

import { ConsoleOfferCodeReverse, TimestampEnum, UrlTypeEnum } from '../enum/toConsole';

const ASCM_IFRAME = '/entryPoint';

// 获取url上的参数
function getUrlParams(url: string): any {
  const index = url.indexOf('?');
  const { length } = url;
  const urlStr = url.substring(index, length);
  const urlSearchParams = new URLSearchParams(urlStr);
  return Object.fromEntries(urlSearchParams.entries());
}

// 是否包含${}占位符,并替换参数
function replaceParam(str: string, param: any): string {
  console.log('str', str);
  let strC = str;
  // 使用正则表达式匹配${}的模式
  const reg = /\$\{([^}]+)\}/g;
  // 判断是否存在${}
  if (reg.test(str)) {
    // 替换${role}里的内容
    strC = str.replace(reg, (match, p1) => {
      if (param?.[p1]) {
        return encodeURIComponent(param[p1]);
      }
      return '';
    });
  }
  if (str.includes('#') && !str.includes('{')) {
    return strC;
  }
  return encodeURIComponent(strC);
}

const transformURL = (url: string, roleMap?: any): string => {
  console.log('roleMap', roleMap);
  let newUrl = `${url.split('?')[0]}?`;
  let params = '';
  const urlParams = getUrlParams(url);
  console.log('=========urlParams', urlParams);
  for (const key in urlParams) {
    params += `${key}=${replaceParam(urlParams[key], roleMap)}&`;
  }
  newUrl = `${newUrl}${params}${TimestampEnum.timestamp}=${moment().valueOf()}`;
  console.log('newURL', newUrl);
  console.log('params', params);
  return newUrl;
};

// 控制台链接添加后缀
export function consoleUrlFormat(urlAddress: string,roleMap?: any) {
  if (!urlAddress) {
    return '';
  }
  let newUrl = urlAddress;
  if (urlAddress.includes('?')) {
    const roles = sessionStorage.getItem('console_role');
    const roleMap_ = roleMap || (roles && JSON.parse(roles));
    newUrl = transformURL(urlAddress, roleMap_);
  } else {
    newUrl = `${urlAddress}?${TimestampEnum.timestamp}=${moment().valueOf()}`;
  }
  return newUrl;
}

// 跳转控制台
export const jumpToConsole = (record: any,roleMap:any, toConsole: (props: ToConsoleProps) => void) => {
  console.log('🚀 ~ file: product.ts ~ line 40 ~ productItemTrigger ~ record', record);
  const {
    urlType, // 地址类型， 分别有内网-LAN ｜ 外网-WAN ｜ 外网有菜单-EWM
    productOfferName, // 产品的名称
    urlAddress, // 跳转地址
    offerCode, // 唯一标识
  } = record;

  // 外网有菜单-EWM
  if (UrlTypeEnum.ExternalMenu === urlType) {
    const { catalogId, productOfferType } = record;
    toConsole({
      targetRoute: `${ASCM_IFRAME}?security=true&urlType=${urlType}&Item=${window.btoa(
        JSON.stringify({
          catalogId,
          productOfferType,
          productOfferName,
        })
      )}&${TimestampEnum.timestamp
        }=${moment().valueOf()}&targetUrl=${window.btoa(
          consoleUrlFormat(urlAddress)
        )}&productType=${ConsoleOfferCodeReverse[offerCode]}`,
      isBlank:true
    })
    return null;
  }
  // 内网
  if (UrlTypeEnum.Internal === urlType) {
    toConsole({ targetRoute: urlAddress,isBlank:true });
  } else {
    if (!urlAddress.startsWith('http')) {
      toConsole({ targetRoute: urlAddress,isBlank:true });
      return null;
    }
    // 外网
    const url = `${ASCM_IFRAME}?productType=${ConsoleOfferCodeReverse[offerCode]}&urlType=${urlType}&targetUrl=${window.btoa(consoleUrlFormat(urlAddress))}`;
    toConsole({ targetRoute: url,isBlank:true });
  }
  return null;
};


const isSecurityProduct = (url: string) => {
  const allProduct = localStorage.getItem('allProduct');
  if (!allProduct || !url) return false;
  const { subCatalogs } = JSON.parse(allProduct);
  let state = false;
  subCatalogs?.forEach((subCatalog: any) => {
    const { productList } = subCatalog;
    const item = find(productList, ['urlAddress', url]);
    if (item?.urlType === UrlTypeEnum.ExternalMenu) {
      state = item;
    }
  });
  return state;
};
const jumpToSecurityProduct = (Item: any, toConsole: (props: ToConsoleProps) => void) => {
  const { catalogId, productOfferType, productOfferName, urlAddress, offerCode } = Item;
  const url = `${ASCM_IFRAME}?security=true&Item=${window.btoa(
    JSON.stringify({
      catalogId,
      productOfferType,
      productOfferName,
    })
  )}&${TimestampEnum.timestamp
    }=${moment().valueOf()}&targetUrl=${window.btoa(
      consoleUrlFormat(urlAddress)
    )}&productType=${PurchaseOfferCodeReverse[offerCode]}`;

  toConsole({ targetRoute: url,isBlank:true });
};
export const onToHJIframeByRequest = (url: string, toConsole: (props: ToConsoleProps) => void, requestData?: any) => {
  if (!url) return null;
  const item = isSecurityProduct(url);
  if (item) {
    jumpToSecurityProduct(item, toConsole);
    return false;
  }
  // TODO 这里要改成navigate
  const query = new URLSearchParams({ targetUrl: window.btoa(url), ...requestData }).toString();
  toConsole({ targetRoute: `${ASCM_IFRAME}?${query}` ,isBlank:true})

  return null;
};
// 跳转购买页
export const jumpToPurchase = (record: any, regionList: any[], toConsole: (props: ToConsoleProps) => void) => {
  const { offerCode, resourceUrlAddress, platform, urlAddress } = record;
  const reverseOfferCode = PurchaseOfferCodeReverse[offerCode];
  if (
    reverseOfferCode === 'SLS'
  ) {
    onToHJIframeByRequest(consoleUrlFormat(urlAddress), toConsole, { productType: reverseOfferCode });
    return;
  }
  if (resourceUrlAddress) {
    toConsole({ targetRoute: resourceUrlAddress,isBlank:true })
    return;
  }
  // 以上是原有的逻辑，以下是新增的平台根据平台方式进入entrypoint
  if (platform === 'QINGCLOUD') {
    toConsole({ targetRoute: `/entrypoint?platform=${platform}&offerCode=${offerCode}&opera=purchase`,isBlank:true })
    return;
  }
  if (reverseOfferCode) {
    const aliyunPlatform = regionList.find(item => item.cloudPlatform === PlatformEnum.aliyun)?.cloudPlatform;
    const apsaraPlatform = regionList.find(item => item.cloudPlatform === PlatformEnum.apsara)?.cloudPlatform;
    let newPlatform: string;
    if (['EXP', 'NAT', 'NAS', 'OSS', 'VPN', 'WAF', 'PSQL', 'MRS', 'MSC'].includes(reverseOfferCode)) {
      newPlatform = apsaraPlatform;
    } else if (['REDIS', 'mariaDB'].includes(reverseOfferCode)) {
      newPlatform = aliyunPlatform;
    } else {
      newPlatform = regionList[0].cloudPlatform;
    }
    if (
      reverseOfferCode === 'MSL' ||
      reverseOfferCode === 'SQL' ||
      reverseOfferCode === 'PSQL' ||
      reverseOfferCode === 'mariaDB'
    ) {
      toConsole({ targetRoute: `/entrypoint?pageMatch=true&scene=rds&opera=${reverseOfferCode}&platform=${newPlatform}` ,isBlank:true})
      return;
    }

    toConsole({ targetRoute: `/entrypoint?pageMatch=true&scene=${reverseOfferCode}&opera=purchase&platform=${newPlatform}`,isBlank:true })
  }
};
