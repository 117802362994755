import React from 'react';

import styles from './index.less';

interface FeaturesProps {
  id?: string;
  items: {
    title: string;
    description: string;
    iconUrl: any;
  }[];
}
const Features = (props: FeaturesProps) => {
  const { id, items } = props;
  return (
    <div id={id} className={styles['price-highlights-container']}>
    
      <div className={styles['price-highlights-contain']}>
        {
        items.map(item => {
          return (
            <div key={item.title} className={styles['price-highlights-content']}>
              <div>
                <div className={styles['price-highlights-content-img']}>
                  <img src={item.iconUrl} alt="" />
                </div>
                <div className={styles['price-highlights-content-item']}>
                  <span>{item.title}</span>
                  <div>{item.description}</div>
                </div>
              </div>
            </div>
          );
        })
      }
      </div>
    </div>
  );
};

export default Features;
